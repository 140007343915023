<template>
    <div class="oil-communication-modal-table">
        <template v-if="view === 'header'">
            <div v-for="item in content" :key="item" class="oil-communication-modal-table__row">
                <span class="oil-communication-modal-table__row-cell">{{ item.communication.text }}</span>
                <span class="oil-communication-modal-table__row-cell" @mouseenter="visibleTooltip = item.capex.tooltipId" @mouseleave="visibleTooltip = false">{{ item.capex.text }}
                    <transition name="fade">
                        <span class="oil-communication-modal-table__row-cell-tooltip" v-if="visibleTooltip === item.capex.tooltipId && item.capex.tooltip">{{ item.capex.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-communication-modal-table__row-cell" @mouseenter="visibleTooltip = item.tu.tooltipId" @mouseleave="visibleTooltip = false">{{ item.tu.text }}
                    <transition name="fade">
                        <span class="oil-communication-modal-table__row-cell-tooltip" v-if="visibleTooltip === item.tu.tooltipId && item.tu.tooltip">{{ item.tu.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-communication-modal-table__row-cell" @mouseenter="visibleTooltip = item.wfu.tooltipId" @mouseleave="visibleTooltip = false">{{ item.wfu.text }}
                    <transition name="fade">
                        <span class="oil-communication-modal-table__row-cell-tooltip" v-if="visibleTooltip === item.wfu.tooltipId && item.wfu.tooltip">{{ item.wfu.tooltip }}</span>
                    </transition>
                </span>
                <span class="oil-communication-modal-table__row-cell" @mouseenter="visibleTooltip = item.eu.tooltipId" @mouseleave="visibleTooltip = false">{{ item.eu.text }}
                    <transition name="fade">
                        <span class="oil-communication-modal-table__row-cell-tooltip" v-if="visibleTooltip === item.eu.tooltipId && item.eu.tooltip">{{ item.eu.tooltip }}</span>
                    </transition>
                </span>
            </div>
        </template>
        <template v-else-if="view === 'content'">
            <div v-for="item in content" :key="item.id" class="oil-communication-modal-table__row _content" :class="{_disable: item.status === 'NotAvailable'}">
                <div class="oil-communication-modal-table__row-cell">
                    <radio-cmp 
                        :text="item.communication"
                        :id="item.communication"
                        :active="activeComminication"
                        @setRadio="setCommunicationType"
                    />
                </div>
                <span class="oil-communication-modal-table__row-cell">{{ item.capex }}</span>
                <span class="oil-communication-modal-table__row-cell">{{ item.wfu }}</span>
                <span class="oil-communication-modal-table__row-cell">{{ item.tu }}</span>
                <span class="oil-communication-modal-table__row-cell">{{ item.eu }}</span>
                <svg @mouseenter="tooltip = item.id" @mouseleave="tooltip = false"  class="oil-communication-modal-table__row-info" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#398BDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <transition name="fade">
                    <div class="oil-communication-modal-table__row-info-tooltip"  v-if="tooltip === item.id">
                        <span>{{ item.tooltip }}</span>
                    </div>
                </transition>
            </div>
        </template>
    </div>
</template>
<script>
    import radioCmp from '../../../../ui-components/oil-radio/radio-cmp.vue'
    import { mapMutations } from 'vuex'

    export default {
        props: {
            view: {
                type:String, 
                default: 'type'
            },
            content: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                tooltip: false,
                communication: 'Road',
                activeComminication: 'Дорога',
                visibleTooltip: false
            }
        },
        components: {
            'radio-cmp': radioCmp
        },
        methods: {
            ...mapMutations('building', ['setTypeCommunication']),

            setCommunicationType(val) {
                console.log(this.$props.content);
                this.activeComminication = val
                this.communication = this.$props.content.find(item => item.communication === val).id
                this.setTypeCommunication(this.communication)
            }
        }
    }
</script>