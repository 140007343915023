import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "oil-flat-projection" }
const _hoisted_2 = { class: "oil-flat-projection__general-map" }
const _hoisted_3 = {
  key: 0,
  class: "oil-flat-projection__general-map__surface-image"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["x", "y", "title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_communication_cmp = _resolveComponent("communication-cmp")!
  const _component_map_cmp = _resolveComponent("map-cmp")!
  const _component_arrangement_cmp = _resolveComponent("arrangement-cmp")!
  const _component_borehole_cmp = _resolveComponent("borehole-cmp")!
  const _component_seismic_cmp = _resolveComponent("seismic-cmp")!
  const _component_polygon_cmp = _resolveComponent("polygon-cmp")!
  const _component_trajectory_cmp = _resolveComponent("trajectory-cmp")!
  const _component_detailed_cell = _resolveComponent("detailed-cell")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.maps, (img_link) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: img_link }, [
          (_ctx.active_map.find(map => map.id === img_link.id)!.active)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: `data:image/png;base64,${img_link.img}`
                }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(22, (row) => {
        return _createElementVNode("div", {
          key: `col-${row}`,
          class: "oil-flat-projection__general-map__column"
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(16, (column) => {
            return _createElementVNode("div", {
              class: _normalizeClass(["oil-flat-projection__general-map__column-cell", {'_plane-communication': _ctx.buildingCommunication(row, column) && _ctx.data_type === 'communication', '_disabled': row >= 26 && row <= 26 || column >= 26 && column <= 26}]),
              key: `row-${column}`,
              x: row,
              y: column,
              ref_for: true,
              ref: `map_container_x${row}_y${column}`,
              title: `Участок x ${row} y ${column}`,
              onClick: ($event: any) => (_ctx.activityOnCell(row, column), _ctx.setCommunicationLine(row, column))
            }, [
              (_ctx.communication_lines.lines.some(line => line.x === row && line.y === column) && (row || column) !== 25)
                ? (_openBlock(), _createBlock(_component_communication_cmp, {
                    key: 0,
                    lines: _ctx.mappingCommunicationLine(row, column),
                    data_type: _ctx.data_type,
                    coords: {x: row, y: column}
                  }, null, 8, ["lines", "data_type", "coords"]))
                : _createCommentVNode("", true),
              (_ctx.mapMapping(row, column) && _ctx.data_type === 'maps')
                ? (_openBlock(), _createBlock(_component_map_cmp, {
                    key: 1,
                    type_map: _ctx.type_map,
                    data_type: _ctx.data_type,
                    coords: {x: row, y: column}
                  }, null, 8, ["type_map", "data_type", "coords"]))
                : _createCommentVNode("", true),
              (_ctx.arrangement_map.arrangement.some(arrangement => arrangement.x === row && arrangement.y === column))
                ? (_openBlock(), _createBlock(_component_arrangement_cmp, {
                    key: 2,
                    data_type: _ctx.data_type,
                    arrangements: _ctx.mappingArrangement(row, column)
                  }, null, 8, ["data_type", "arrangements"]))
                : _createCommentVNode("", true),
              (_ctx.boreholes_dots.boreholes.some(borehole => borehole.x === row && borehole.y === column))
                ? (_openBlock(), _createBlock(_component_borehole_cmp, {
                    key: 3,
                    data_type: _ctx.data_type,
                    boreholes: _ctx.mappingBorehole(row, column)
                  }, null, 8, ["data_type", "boreholes"]))
                : _createCommentVNode("", true),
              (_ctx.seismic_lines.seismic.some(seismic => seismic.startX === row && seismic.endX === row || seismic.startY === column && seismic.endY === column))
                ? (_openBlock(), _createBlock(_component_seismic_cmp, {
                    key: 4,
                    data_type: _ctx.data_type,
                    seismics: _ctx.seismic_lines.seismic,
                    coords: {x: row, y: column}
                  }, null, 8, ["data_type", "seismics", "coords"]))
                : _createCommentVNode("", true),
              (_ctx.boreholes_dots.boreholes.some(borehole => borehole.x === row && borehole.y === column))
                ? (_openBlock(), _createBlock(_component_polygon_cmp, {
                    key: 5,
                    data_type: _ctx.data_type,
                    seismics: _ctx.seismic_lines.seismic,
                    coords: {x: row, y: column}
                  }, null, 8, ["data_type", "seismics", "coords"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.borehole_trajectory.borehole, (trajectory_item, idx) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                  (trajectory_item.trajectory.some(trajectory => trajectory.x === row && trajectory.y === column))
                    ? (_openBlock(), _createBlock(_component_trajectory_cmp, {
                        key: 0,
                        class: _normalizeClass({_visible: !_ctx.visible_trajectory}),
                        direction: _ctx.trajectoryMapping(row,column, idx)
                      }, null, 8, ["class", "direction"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ], 10, _hoisted_5)
          }), 64))
        ])
      }), 64))
    ]),
    (_ctx.region.coords.x && _ctx.region.coords.y)
      ? (_openBlock(), _createBlock(_component_detailed_cell, {
          key: 0,
          objects: _ctx.objects_inner_cell,
          coords_region: _ctx.region.coords,
          data_type: _ctx.$props.data_type
        }, null, 8, ["objects", "coords_region", "data_type"]))
      : _createCommentVNode("", true)
  ]))
}