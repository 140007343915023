
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        boreholes: {
            type: Array,
            default: () => []
        },
        data_type: {
            type: String,
            default: ''
        },
        trajectory: {
            type: Array,
            default: () => []
        }
    },
    setup() {

    },
})
