
import { defineComponent, reactive, onMounted, watch } from 'vue';
import menubarCmp from '@/components/ui-components/oil-menubar/menubar-cmp.vue';
import { Chart } from 'highcharts-vue';
import { useRoute } from 'vue-router'
import buttonCmp from '@/components/ui-components/oil-button/button-cmp.vue';
import statisticTableCmp from '@/components/ui-components/oil-statistic-table/research-table/research-table.vue';
// import statisticGtmCmp from "@/components/ui-components/oil-statistic-table/gtm-table/gtm-table.vue"
import axios from 'axios';
import { useStore } from 'vuex'

export default defineComponent({
    setup() {
        const route = useRoute()
        const store = useStore()

        
        const menubar_list = [
            {
                text: 'Информация о скважине' as string,
                id: 1 as number
            },
            {
                text: 'Исследования' as string,
                id: 2 as number
            },
            // {
            //     text: 'ГТМ' as string,
            //     id: 3 as number
            // },
        ]

        const active_menubar = reactive({
            menu_id: 1 as number
        })

        const status_table = reactive({
            table: []
        })

        const chartOptions = reactive({
            charts: []
        })

        const table_template = reactive([
            {
                date: {
                    text: "Дата"
                },
                boreholeStatus: {
                    text: "Статус скважины",
                },
                flowrateReservoir: {
                    text: "Qпласт. ж., м3/сут"
                },
                flowrateWater: {
                    text: "Qводы, м3/сут"
                },
                flowrateOil: {
                    text: "Qнефти, м3/сут"
                },
                flowrateGas: {
                    text: "Qгаза, м3/сут"
                },
                flowrateCondensate: {
                    text: "Qконд, м3/сут"
                },
                pressureBottomhole: {
                    text: "Рзаб, атм"
                },
                pressureSupply: {
                    text: "Руст, атм"
                },
            },
            {
                date: {
                    text: "25.10.2022"
                },
                boreholeStatus: {
                    text: "Исследование",
                    color: "#DCFFF0"
                },
                flowrateReservoir: {
                    text: "5"
                },
                flowrateWater: {
                    text: "5"
                },
                flowrateOil: {
                    text: "5"
                },
                flowrateGas: {
                    text: "5"
                },
                flowratecondensate: {
                    text: "5"
                },
                pressureBottomhole: {
                    text: "5"
                },
                pressureSupply: {
                    text: "5"
                },
            },
            {
                date: {
                    text: "25.10.2022"
                },
                boreholeStatus: {
                    text: "Исследование",
                    color: "#DCFFF0"
                },
                flowrateReservoir: {
                    text: "5"
                },
                flowrateWater: {
                    text: "5"
                },
                flowrateOil: {
                    text: "5"
                },
                flowrateGas: {
                    text: "5"
                },
                flowratecondensate: {
                    text: "5"
                },
                pressureBottomhole: {
                    text: "5"
                },
                pressureSupply: {
                    text: "5"
                },
            },
            {
                date: {
                    text: "25.10.2022"
                },
                boreholeStatus: {
                    text: "Исследование",
                    color: "#DCFFF0"
                },
                flowrateReservoir: {
                    text: "5"
                },
                flowrateWater: {
                    text: "5"
                },
                flowrateOil: {
                    text: "5"
                },
                flowrateGas: {
                    text: "5"
                },
                flowratecondensate: {
                    text: "5"
                },
                pressureBottomhole: {
                    text: "5"
                },
                pressureSupply: {
                    text: "5"
                },
            },
            {
                date: {
                    text: "25.10.2022"
                },
                boreholeStatus: {
                    text: "Исследование",
                    color: "#DCFFF0"
                },
                flowrateReservoir: {
                    text: "5"
                },
                flowrateWater: {
                    text: "5"
                },
                flowrateOil: {
                    text: "5"
                },
                flowrateGas: {
                    text: "5"
                },
                flowratecondensate: {
                    text: "5"
                },
                pressureBottomhole: {
                    text: "5"
                },
                pressureSupply: {
                    text: "5"
                },
            },
        ]) 

        const name_table = reactive({
            table: [],
            name: ''
        })

        const new_table = reactive({
            table: []
        })

        const disable_load_more = reactive({
            state: false
        })

        const loadFile = () => {
            axios 
                .get(`Views/Browser/Statistic/Borehole/Debit/TableUnloadCsv/${route.params.id}`,
                    { responseType: 'blob' })
                    
                .then(resp => {
                    const href = URL.createObjectURL(resp.data)
                    const link = document.createElement('a')
                    link.href = href 
                    link.setAttribute('download', `${name}.csv`)
                    document.body.appendChild(link)
                    link.click()

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch(err => console.log(err))
        }
        
        const loadTable = () => {
            axios 
                .get(`Views/Browser/Statistic/Borehole/Debit/table/${route.params.id}?page=${Math.ceil((status_table.table.slice(1).length / 20) + 1)}`)
                .then((response) => {
                    new_table.table = response.data  
                    console.log(response.data.length);
                    
                    disable_load_more.state = response.data.length < 20 ? true : false                     
                })
                .finally(() => {
                })
                .catch(err => {
                    store.commit('notification/openNotification',{
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        }

        watch(() => new_table.table, () => {
            status_table.table = status_table.table.concat(new_table.table);
        });

        onMounted(() => {            
            axios
                .get(`Views/Browser/Statistic/Borehole/Debit/table/${route.params.id}?page=1`)
                .then((response) => {
                    status_table.table = response.data
                })
                .catch(err => {
                    store.commit('notification/openNotification',{
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
            
            axios 
                .get(`Views/Browser/Statistic/Borehole/Debit/chart/${route.params.id}`)   
                .then(response => {
                    chartOptions.charts = response.data
                }) 
                .catch(err => {
                    store.commit('notification/openNotification',{
                        error: true,
                        title: 'Ошибка!',
                        desc: err.response.data
                    })
                })
        })

        const selectMenu = (selected_menu: number) => {
            active_menubar.menu_id = selected_menu
        }

        return {
            menubar_list,
            active_menubar,
            table_template,
            status_table,
            route,
            name_table,
            selectMenu,
            chartOptions,
            new_table,
            disable_load_more,
            loadTable,
            loadFile
        }
    },
    components: {
        'menubar-cmp': menubarCmp,
        highcharts: Chart,
        'btn-cmp': buttonCmp,
        'statistic-table-cmp': statisticTableCmp,
        // 'statistic-gtm-table-cmp': statisticGtmCmp,
    }
})

