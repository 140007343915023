
    import tableCmp from './table-cmp/table-cmp.vue'
    import btnCmp from '../../../ui-components/oil-button/button-cmp.vue'
    import { useStore } from 'vuex'
import { onMounted } from 'vue';

    export default {
        props: {
            data: {
                type: Array,
                default: () => []
            },
            main_class: {
                type: String,
            }
        },
        setup(props) {
            const store = useStore()

            const closeModal = () => {
                store.commit('modal/closeModal')
            }

            const startBuilding = () => {
                store.commit('building/setBuilding', true)
                store.commit('modal/closeModal')
            }
            
            onMounted(() => {
                console.log(props.data);
            })
            

            return {
                startBuilding,
                closeModal
            }
        },
        components: {
            'table-cmp': tableCmp,
            'btn-cmp': btnCmp
        },
    }
