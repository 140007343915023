
import { defineComponent, reactive, onMounted, watch } from 'vue'

export default defineComponent({
	props: {
		text: {
			type: String,
			default: "text",
		},
		status: {
			type: String,
			default: 'Available'
		},
		id: {
			type: [Number, String],
			default: 0
		},
		type_checkbox: {
			type: String,
			default: ''
		},
		visible: {
			type: Object,
			default: () => {}
		},
		activeCheckbox: {
			type: String || Boolean,
			default: ''
		}
	},
	setup(props, { emit }) {
		const active_checkbox = reactive({
			state: false as boolean,
			permanent_active: false as boolean
		})

		const changeStateCheckbox = () => {
			if(!active_checkbox.permanent_active) {
				active_checkbox.state = !active_checkbox.state

				emit('set-obj-checkbox', { checkbox_state: active_checkbox.state, checkbox_id: props.id, checkbox_type: props.type_checkbox })				
			}
		}

		watch(() => props.activeCheckbox, () => {
			if(props.activeCheckbox) {
				
				active_checkbox.state = true
			} else {
				active_checkbox.state = false
			}
		})

		onMounted(() => {
			if(props.activeCheckbox) {
				active_checkbox.state = true
			}
		
			switch (props.status) {
				case 'InProgress':
					active_checkbox.permanent_active = true

					break;
			
				case 'Complete':
					active_checkbox.permanent_active = true

					break;
			
				case 'Planned':
					active_checkbox.permanent_active = true

					break;
			
				default:
					break;
			}
		})

		return {
			changeStateCheckbox,
			active_checkbox
		}
	},
//   data() {
//     return {
//       active: false,
//       map: '',
//       boreholeLog: ''
//     };
//   },
//   watch: {
//     activeCheckbox() {
//       if(this.$props.activeCheckbox !== '') {
//         this.active = true
//       }
//     },
//     map() {
//       this.$emit('set-maps', this.map);
//     }
//   },
//   methods: {
//     setValue(text, status, id) {
//       if(status === 'InProgress' || status === 'Complete') {
//         this.active = true;
//         console.log('qwerty1');
//       } else {
//         if(this.activeCheckbox !== '' && this.activeCheckbox  && this.activeCheckbox !== 'Эксплуатационная колонна' ) {
//           this.active = true
//           console.log('qwerty2');
//         } else {
//           console.log('qwert');
//           this.active = !this.active
//         }
        
//         this.map = {text, id}
//       }
//       this.boreholeLog = id
//       this.$emit('set-boreholeLog', this.boreholeLog)
//       this.$emit('set-checkbox', this.active);
//       this.$emit('set-obj-checkbox', {active: this.active, id: this.$props.id, type_checkbox: this.$props.type_checkbox})
//     },
//   },
//   created() {
//     if(this.status === 'InProgress' || this.status === 'Complete' || this.status === 'True' || this.status === 'Planned' || this.status === 'Спутниковая фотография') {
//       this.active = true
//     }
//     if(this.$props.visible) {
//       for(let i = 0; i < this.$props.visible.length; i++) {
//         if((this.$props.visible[i].val.id === this.$props.id)) {
//           this.active = true
//         }
//       }
//     }
    
//   }
})
