
export default {
  name: "v-btn",
  props: {
    text: {
      type: String,
      default: "button"
    },
    background: {
      type: Boolean,
      default: true
    },
    done: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
}
