import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../../assets/img/borehole.png'


const _hoisted_1 = { class: "oil-detailed-cell" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["y", "x"]
const _hoisted_5 = ["title", "onMouseenter", "idx"]
const _hoisted_6 = {
  key: 0,
  src: _imports_0
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onMouseenter", "idx"]
const _hoisted_9 = {
  key: 0,
  src: _imports_0
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (row) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: row }, [
        (_ctx.$props.data_type === 'arrangement')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (column) => {
              return _createElementVNode("div", {
                key: column,
                class: "oil-detailed-cell__field",
                onClick: ($event: any) => (_ctx.openActiveTypeModal(row, column))
              }, [
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    (_ctx.$props.objects?.arrangement.find(object => object.xInCell === row && object.yInCell === column)?.imageLink)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          style: _normalizeStyle({backgroundColor: _ctx.$props.objects?.arrangement.find(object => object.xInCell === row && object.yInCell === column)?.color}),
                          class: "oil-detailed-cell__field__container"
                        }, [
                          _createElementVNode("img", {
                            src: `${_ctx.$root.host}${_ctx.$props.objects?.arrangement.find(object => object.xInCell === row && object.yInCell === column)?.imageLink}`
                          }, null, 8, _hoisted_3)
                        ], 4))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ], 8, _hoisted_2)
            }), 64))
          : _createCommentVNode("", true),
        (_ctx.$props.data_type === 'borehole')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(3, (column) => {
              return _createElementVNode("div", {
                key: column,
                class: "oil-detailed-cell__field",
                y: row,
                x: column
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (count, idx) => {
                  return _createElementVNode("div", {
                    class: _normalizeClass(["oil-detailed-cell__field__well-pad _vertical", {
                            _selected: _ctx.isCellInLine(null, column, '_vertical'), 
                            '_purchased-well-pad': _ctx.mappingOfPurchasedWellPad(column, '_vertical'),
                            '_disabled-well-pad': !_ctx.mappingOfPurchasedWellPad(column, '_vertical') && _ctx.objects.borehole.id,
                        }]),
                    title: _ctx.mappingOfPurchasedBoreholeName(row, column, count, '_vertical'),
                    onMouseenter: ($event: any) => (_ctx.hightlightLine(null, column)),
                    onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeHighlighting && _ctx.removeHighlighting(...args))),
                    key: count + idx,
                    style: _normalizeStyle({top: idx === 0 ? '5%' : idx === 2 ? '95%': idx * 50 + '%', transform: `translate(-50%, -${idx * 50 + '%'})`}),
                    idx: count
                  }, [
                    (_ctx.mappingOfPurchasedBorehole(row, column, count, '_vertical'))
                      ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          onClick: ($event: any) => (_ctx.buyWellPad(null, column, '_vertical', row, count)),
                          class: "oil-detailed-cell__field__well-pad__borehole"
                        }, null, 8, _hoisted_7))
                  ], 46, _hoisted_5)
                }), 64)),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (count, idx) => {
                  return _createElementVNode("div", {
                    class: _normalizeClass(["oil-detailed-cell__field__well-pad _horizontal", {
                            _selected: _ctx.isCellInLine(row, null, '_horizontal'), 
                            '_purchased-well-pad': _ctx.mappingOfPurchasedWellPad(row, '_horizontal'),
                            '_disabled-well-pad': !_ctx.mappingOfPurchasedWellPad(column, '_horizontal') && _ctx.objects.borehole.id,
                        }]),
                    onMouseenter: ($event: any) => (_ctx.hightlightLine(row, null)),
                    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeHighlighting && _ctx.removeHighlighting(...args))),
                    key: count + idx,
                    style: _normalizeStyle({left: idx === 0 ? '5%' : idx == 2 ? '95%' : idx * 50 + '%', transform: `translate(-${idx * 50 + '%'}, -50%)`}),
                    idx: count
                  }, [
                    (_ctx.mappingOfPurchasedBorehole(row, column, count, '_horizontal'))
                      ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          onClick: ($event: any) => (_ctx.buyWellPad(row, null, '_horizontal', column, count)),
                          class: "oil-detailed-cell__field__well-pad__borehole"
                        }, null, 8, _hoisted_10))
                  ], 46, _hoisted_8)
                }), 64))
              ], 8, _hoisted_4)
            }), 64))
          : _createCommentVNode("", true)
      ], 64))
    }), 64))
  ]))
}