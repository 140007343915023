<template>
    <div :class="`${main_class}__settings`">
        <span :class="`${main_class}__settings__title`" v-if="data.type === 'Borehole'">Настройки скважины</span>
        <span :class="`${main_class}__settings__title`" v-else>Изменение статуса объекта</span>
        <div :class="`${main_class}__settings__info`">
            <div :class="`${main_class}__settings__info__well`">
                <div :class="`${main_class}__settings__info__well__number`" v-if="data.type === 'Borehole'">
                    <span>Скважина:</span>
                    <span>{{ $props.data.status_list.boreholeName.text.length ? $props.data.status_list.boreholeName.text : '' }}</span>
                </div>
                <div :class="`${main_class}__settings__info__well__type`" v-if="data.type === 'Borehole'">
                    <span>Тип скважины:</span>
                    <span>{{ $props.data.status_list.boreholeType.text }}</span>
                </div>
                <div :class="`${main_class}__settings__info__well__status`">
                    <span>Статус: </span>
                    <span :class="`${main_class}__settings__info__well__status__wrapper`" v-if="data.type === 'Borehole'">
                        <span :class="`${main_class}__settings__info__well__status__value`" :style="{ backgroundColor: $props.data.status_list.boreholeStatus.color }">{{ $props.data.status_list.boreholeStatus.text }}</span>
                        <svg v-show="$props.data.status_list.boreholeStatus.isAutomatic" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 3.8V8L10.8 9.4M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#5B6C7B" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <span :class="`${main_class}__settings__info__well__status__value`" v-else :style="{ backgroundColor: $props.data.status_color }">{{ data.status_list.find(status => status.status === 'Current').text }}</span>
                </div>
                <div :class="`${main_class}__settings__info__well__pump`" v-if="data.type === 'Borehole'">
                    <span>Насос:</span>
                    <span :class="`${main_class}__settings__info__well__pump__value`">{{ $props.data.status_list.pump.text }}</span>
                </div>
            </div>
            <div v-if="$props.data.status_list.boreholeMonitoring === null" :class="`${main_class}__settings__info__message`">В таких статусах эксплуатационной скважины как “Заморозка”, “Добыча”, “Нагнетание” и “Консервация” вы можете задать значения забойного давления <span>P<sub>заб</sub></span> и дебит <span>Q</span> или приёмистость <span>i<sub>w</sub></span> скважины.</div>
            <div v-else :class="`${main_class}__settings__info__control`">
                <div :class="`${main_class}__settings__info__control__header`" v-if="data.type === 'Borehole'">
                    <span :class="`${main_class}__settings__info__control__header__title`">Контроль скважины через:</span>
                    <span :class="`${main_class}__settings__info__control__header__subtitle`">(кнопкой отметьте параметр, с помощью которого будет происходить контроль скважины)</span>
                </div>
                <div :class="`${main_class}__settings__info__control__wrapper`">
                    <div v-for="(radio_monitoring, idx) in data.monitoring_data" :key="radio_monitoring.monitoringType" :class="`${main_class}__settings__info__control__wrapper__value`">
                        <radio-cmp 
                            :class="`${main_class}__settings__info__control__wrapper__value__radio`"
                            :id="radio_monitoring.isChecked"
                            :active="active_monitoring.value"
                            :status="radio_monitoring.monitoringType"
                            :text="radio_monitoring.formula"
                            @setId="setRadio($event, idx)"
                        />
                        <input-cmp
                            :class="`${main_class}__settings__info__control__wrapper__value__input`"
                            :initValue="radio_monitoring.value"
                            :type="radio_monitoring.monitoringType"
                            @set="setValueMonitoring"
                        />
                        <div v-html="radio_monitoring.label"></div>
                    </div>
                </div>
            </div>
            <div :class="`${main_class}__settings__info__status`">
                <span :class="`${main_class}__settings__info__status__title`">Выберите новый статус  <span v-if="data.type === 'Borehole'">скважины</span><span v-else>объекта обустройства</span></span>
                <div :class="`${main_class}__settings__info__status__table`">
                    <template v-if="data.type === 'Borehole'">
                        <radio-cmp 
                            v-for="status in data.status_list.statuses"
                            :key="status"
                            :text="status.text"
                            :id="status.id"
                            :status="status.status"
                            :active="status_id.status_text"
                            @setId="setStatusId"
                            :class="[`${main_class}__settings__info__status__table__radio`, { '_disable': status.status === 'NotAvailable' }]"
                        />
                    </template>
                    <template v-else>
                        <radio-cmp 
                            v-for="status in data.status_list"
                            :key="status"
                            :text="status.text"
                            :id="status.id"
                            :status="status.status"
                            :active="status_id.status_text"
                            @setId="setStatusId"
                            :class="[`${main_class}__settings__info__status__table__radio`, { '_disable': status.status === 'NotAvailable' }]"
                        />
                    </template>
                </div>
            </div>
            <div :class="`${main_class}__settings__info__btn`">
                <btn-cmp
                    :class="`${main_class}__settings__info__btn__accept`"
                    :text="'Применить'"
                    @click="changeStatus"
                />
            </div>
        </div>
    </div>
</template>
<script>
import radioCmp from '../../../ui-components/oil-radio/radio-cmp.vue'
import btnCmp from "../../../ui-components/oil-button/button-cmp.vue"
import inputCmp from "../../../ui-components/oil-input/input-cmp.vue"
import { mapMutations, mapState } from 'vuex'
import { useStore } from 'vuex'
import { defineComponent, reactive, watch, onMounted } from 'vue'
import axios from 'axios'

    export default defineComponent({
        props: {
            data: {
                type: Array,
                default: () => []
            },
            main_class: {
                type: String,
            }
        },
        setup(props) {
            const status_id = reactive({
                status_text: ""
            })

            const active_monitoring = reactive({
                active_radio: 1,
                value: "Pressure"
            })

            // const test_array = reactive({
            //     test_data: [
            //         {
            //             monitoringType: "Pressure",
            //             value: 200,
            //             isChecked: true
            //         },
            //         {
            //             monitoringType: "Flowrate",
            //             value: 0,
            //             isChecked: false
            //         },
            //         {
            //             monitoringType: "Injectability",
            //             value: 0,
            //             isChecked: false
            //         }
            //     ]
            // })

            const monitoring_data = reactive({
                value: []
            })

            const store = useStore()

            const setStatusId = (id) => {
                status_id.status_text = id
            }

            const closeModal = () => {
                store.commit('modal/closeModal')
            }

            const setValueMonitoring = (val) => {
                for(let i = 0; i < monitoring_data.value.length; i++) {
                    if(val.type === monitoring_data.value[i].monitoringType) {
                        monitoring_data.value[i].value = val.value
                    }
                }
            }

            const changeStatus = () => {
                if(props.data.type === 'Borehole') {
                    axios
                        .patch(`api/v1/Purchased/${props.data.type}`, {
                            "Id": props.data.id, 
                            "Status": (() => {
                                console.log(status_id.status_text)
                                return status_id.status_text
                                // const plannedStatus = props.data.status_list.statuses.find(status => status.status === 'Planned');
                                // if (plannedStatus) {
                                //     return plannedStatus.id;
                                // } else {
                                //     return status_id.status_text
                                // }
                            })(),
                            "MonitoringType": active_monitoring.value,
                            "PressureMPa": active_monitoring.value === 'Pressure' ? Number(String(monitoring_data.value.find(value_monitoring => value_monitoring.monitoringType === 'Pressure').value).replace(',', '.')) : null,
                            "InjectabilityM3PerDay": active_monitoring.value === 'Injectability' ?  Number(String(monitoring_data.value.find(value_monitoring => value_monitoring.monitoringType === 'Injectability').value).replace(',', '.')) : null,
                            "FlowrateM3PerDay":active_monitoring.value === 'Flowrate' ?  Number(String(monitoring_data.value.find(value_monitoring => value_monitoring.monitoringType === 'Flowrate').value).replace(',', '.')) : null,
                            // "PressureMPa": Number(active_monitoring.value === 'Pressure' ?  (monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value.includes(',') ? monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value.replace(/,/g, '.') : monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value) : null),
                            // "InjectabilityM3PerDay": Number(active_monitoring.value === 'Injectability' ?  (monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value.includes(',') ? monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value.replace(/,/g, '.') : monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value) : null),
                            // "FlowrateM3PerDay": Number(active_monitoring.value === 'Flowrate' ?  (monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value.includes(',') ? monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value.replace(/,/g, '.') : monitoring_data.value.find(obj => obj.isChecked && obj.monitoringType === active_monitoring.value)?.value) : null),
                        })
                        // .then(() => {
                        //     console.log()
                        // })
                        .finally(() => {
                            store.commit('modal/closeModal')
                            store.commit('request/setReloadState', 'borehole')
                        })
                        .catch(err =>  store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        }))
                } else {
                    axios
                        .patch(`api/v1/Purchased/${props.data.type}`, {
                            "Id": props.data.id, 
                            "Status": status_id.status_text,
                        })
                        // .then(() => {
                        //     console.log()
                        // })
                        .finally(() => {
                            store.commit('modal/closeModal')
                            store.commit('request/setReloadState', 'borehole')
                        })
                        .catch(err =>  store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        }))
                }
               
            }

            const setRadio = (radio_id, idx) => {
                active_monitoring.value = props.data.monitoring_data[idx].monitoringType
                monitoring_data.value[idx].isChecked = true
            }
            

            onMounted(() => {
                monitoring_data.value = props.data.monitoring_data;
                // status_id.status_text = props.data.status_list.statuses.find(status => status.status === 'Current').idcon;
                // console.log(monitoring_data.value);
                // active_monitoring.value = monitoring_data.value?.find(monitoring_obj => monitoring_obj.isChecked)?.monitoringType;

                monitoring_data.value = props.data.monitoring_data?.map((monitoring_obj, idx) => {
                    return {
                        ...monitoring_obj,
                        label: monitoring_obj.monitoringType === "Pressure" ? "<span>атм</span>" : "<span>м<sup>3</sup>/сут</span>",
                        formula: monitoring_obj.monitoringType === "Pressure" ? "<span>P<sub>заб</sub> =</span>" : (monitoring_obj.monitoringType === "Flowrate" ? "<span>Q =</span>" : "<span>i<sub>w</sub> =</span>"),
                        id: idx + 1
                    };
                });
            })

            watch(() => monitoring_data.value, (newValue) => {
                if (newValue === undefined || newValue === null) {
                    return
                }
                if (newValue.status_list && newValue.status_list.boreholeMonitoring) {

                    if (newValue.status_list.boreholeMonitoring.length) {
                        monitoring_data.value = newValue.status_list.boreholeMonitoring;
                        status_id.status_text = newValue.status_list.statuses.find(status => status.status === 'Current').idcon;

                        active_monitoring.value = monitoring_data.value.find(monitoring_obj => monitoring_obj.isChecked).monitoringType;

                        monitoring_data.value = monitoring_data.value.map((monitoring_obj, idx) => {
                            return {
                                ...monitoring_obj,
                                label: monitoring_obj.monitoringType === "Pressure" ? "<span>атм</span>" : "<span>м<sup>3</sup>/сут</span>",
                                formula: monitoring_obj.monitoringType === "Pressure" ? "<span>P<sub>заб</sub> =</span>" : (monitoring_obj.monitoringType === "Flowrate" ? "<span>Q =</span>" : "<span>i<sub>w</sub> =</span>"),
                                id: idx + 1
                            };
                        });
                    }
                } else {
                    console.log('monitoring_data')
                }
            });

            watch(props.data.monitoring_data, () => {
                console.log('qwerty');
            })


            return {
                active_monitoring,
                // test_array,
                setStatusId,
                changeStatus,
                closeModal,
                setRadio,
                setValueMonitoring,
                status_id,
                monitoring_data
            }
        },
        data() {
            return {
                choose: '',
                statuses: [],
                visible: false,
                boreholeId: null
            }
        },
        components: {
            'radio-cmp': radioCmp,
            'btn-cmp': btnCmp,
            'input-cmp': inputCmp
        },
        computed: {
            ...mapState('request', ['host'])
        },
        methods: {
            ...mapMutations('modal', ['closeModal']),
            ...mapMutations('request', ['updateMap']),
            ...mapMutations('notification', ['openNotification']),

            setValue(val) {
                this.choose = val
            },
            setId(id) {
                this.boreholeId = id
            }
            // changeStatus() {
            //     const url = `${this.host}api/v1/Purchased/${this.$props.props.type}`

            //     axios
            //         .patch(url, {
            //             id: this.$props.props.id,
            //             status: this.boreholeId
            //         })
            //         .then(() => {
            //             this.closeModal()
            //             this.updateMap()
            //         })
            //         .catch(err => {
            //             this.$root.visibleNotification({
            //                 props: {
            //                     error: true,
            //                     title: "Ошибка",
            //                     desc: err.response.data
            //                 }
            //             })
            //         })
            // }
        },
        // watch: {
        //     statuses() {
        //         if(this.statuses.length) {
        //             this.visible = true
        //             this.boreholeId = this.statuses.find(item => item.status === 'Current').id
        //             this.choose = this.statuses.find(item => item.status === 'Current').status
        //         }
        //     }
        // },
    })
</script>