
import { defineComponent, onMounted, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import iconCmp from '../../ui-components/oil-icons/icons-cmp.vue'
import axios from 'axios'

export default defineComponent({
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {
        const router = useRouter()
        const store = useStore()

        const sidebar_menu = reactive({
            menu: [
                {
                    img: "../img/home-01.png" as string,
                    link: "/" as string,
                    name: "Главная" as string,
                    disable: false as boolean
                }
            ]
        })

        const tooltip = reactive({
            visible: false
        })

        onMounted(() => {
            sidebar_menu.menu = props.data.items
            axios
                .get('Views/Browser/Map/Borehole/Info')
                .then((response) => {
                    if(response.data.items.length) {
                        props.data.items.find(item => item.name === 'Бурение').disable = false

                        
                        // sidebar_menu.menu.find(sidebar => sidebar.name === 'Бурение').disable = true
                    } else {
                        props.data.items.find(item => item.name === 'Бурение').disable = true
                    }
                })
                .finally(() => {
                    props.data.items.find(item => item.name === 'Планирование').disable = true
                })
        })

        watch(() => store.getters['request/reload'], () => {
            axios
                .get('Views/Browser/Map/Borehole/Info')
                .then((response) => {
                    if(!response.data.items.length) {
                        props.data.items.find(item => item.name === 'Бурение').disable = true
                        // sidebar_menu.menu.find(sidebar => sidebar.name === 'Бурение').disable = true
                    } else {
                        props.data.items.find(item => item.name === 'Бурение').disable = false
                    }
                })
                .finally(() => {
                    store.commit('request/setReloadState', '')
                })
        })

        // watch(() => store.getters['request/reload'], (new_state) => {
        //     axios
        //         .get('Views/Browser/Map/Borehole/Info')
        //         .then((response) => {
        //             if(response.data.items.length) {
        //                 console.log(response.data, 'ya')
        //                 const drilling_item = sidebar_menu.menu.find(item => item.name === 'Бурение')
        //                 if (drilling_item) {
        //                     drilling_item.disable = true
        //                 }
        //                 // sidebar_menu.menu.find(sidebar => sidebar.name === 'Бурение').disable = true
        //             }                    
        //         })

        //     if (new_state.length) {
        //         console.log(new_state, sidebar_menu, 'zdarova')
        //         // const drilling_item = sidebar_menu.menu.find(item => item.name === 'Бурение')
        //         // if (drilling_item) {
        //         //     drilling_item.disabled = true
        //         // }
        //     }
        // })

        const openLink = (link, disable) => {
            if(!disable) {
                router.push(link)
            }
        }

        return {
            router,
            tooltip,
            openLink
        }
    },
    data() {
        return {
            open_sidebar: false as Boolean,
            static_menu: [
                {
                    name: "Профиль",
                    link: "#",
                    disable: true
                },
                {
                    name: "База знаний",
                    link: "#",
                    disable: true
                },
                {
                    name: "Настройки",
                    link: "#",
                    disable: true
                },
                {
                    name: 'Поддержка',
                    link: 'https://t.me/oilcase_support',
                    disable: false
                },
                {
                    name: "Выход",
                    link: "#",
                    disable: false
                },
            ]
        }
    },
    computed: {
        navigation_menu() {
            this.completedMenu()
            const navigation_icon = this.$props.data.items
            navigation_icon.find(item => item.name === 'Уведомления').disable = true
            // navigation_icon.find(item => item.name === 'Планирование').disable = true
            return navigation_icon.map((item, idx) => ({ ...item, ...this.$root.sidebar_icons.slice(2)[idx] }))
        },
    },
    methods: {
        completedMenu() {
            this.static_menu.forEach(menu_section => {
                this.$props.data.items.push(menu_section)
            }) 
        },
        openNavigationMenu() {
            this.open_sidebar = !this.open_sidebar
        },
        logOut(name) {
            
            if(name === 'Выход') {
                this.$root.logOutUser()
            } else if(name === 'Поддержка') {
                window.location.href = 'https://t.me/oilcase_support'
            }
        }
    },
    components: {
        iconCmp
    },
})
