
import { defineComponent, reactive, watch } from 'vue';
import { useStore } from 'vuex'
import axios from 'axios'

export default defineComponent({
    props: {
        objects: {
            type: Object,
            default: () => {}
        },
        data_type: {
            type: String,
            default: 'arrangement'
        },
        coords_region: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {                
        const well_pad = reactive({
            buy: false as boolean,
            id: props.objects.borehole.line
        })

        const hoveredRow = reactive({
            state: null as null | number
        })

        const hoveredColumn = reactive({
            state: null as null | number,
        })

        const data_modal = reactive({
            data: []
        })
        
        const store = useStore()

        const mappingOfPurchasedWellPad = (well_pad, directrion) => {            
            if(props.objects.borehole.line === well_pad && props.objects.borehole.isVertical && directrion === '_vertical') {
                return true
            } else if(props.objects.borehole.line === well_pad && !props.objects.borehole.isVertical && directrion === '_horizontal') {
                return true
            }
        }

        const buyWellPad = (row: null | number, column: null | number, direction: string = '', multiply, idx) => {                        
            if(props.objects.borehole.id === 0 && props.objects.borehole.line === 0) {                
                if (direction === '_vertical' && column === hoveredColumn.state && row === null) {  
                    const url = "/api/v1/Purchased/WellPad"

                    let line, isVertical;

                    if(hoveredColumn.state && !hoveredRow.state) {
                        line = hoveredColumn.state
                        isVertical = true
                    } else {
                        line = hoveredRow.state
                        isVertical = false
                    }

                    axios
                        .post(url, {
                            x: props.coords_region.x,
                            y: props.coords_region.y,
                            line: line,
                            isVertical: isVertical
                        })
                        .then(response_well_pad => {
                            well_pad.id = response_well_pad.data
                        })
                        .catch(err => {                 
                            store.commit('notification/openNotification', {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            })
                        })
                        .finally(() => {
                            store.commit('request/setReloadState', 'borehole')
                        })
                } else {
                    const url = "/api/v1/Purchased/WellPad"

                    let line, isVertical;

                    if(hoveredRow.state && !hoveredColumn.state) {
                        line = hoveredRow.state
                        isVertical = false
                    } else {
                        line = hoveredColumn.state
                        isVertical = true
                    }

                    axios
                        .post(url, {
                            x: props.coords_region.x,
                            y: props.coords_region.y,
                            line: line,
                            isVertical: isVertical
                        })
                        .then(response_well_pad => {
                            well_pad.id = response_well_pad.data
                        })
                        .catch(err => {                 
                            store.commit('notification/openNotification', {
                                error: true,
                                title: 'Ошибка!',
                                desc: err.response.data
                            })
                        })
                        .finally(() => {
                            store.commit('request/setReloadState', 'borehole')
                        })
                }
            } else {
                store.commit('modal/openModal', {
                    newState: 'boreholeModal',
                    props: {
                        region: props.coords_region,
                        cell: { x: row, y: column },
                        well_pad: well_pad.id,
                        order_id: multiply === 2 ? idx + 3 : (multiply === 3 ? idx + 6 : idx),
                        line_number: props.objects.borehole.line
                    }
                })
            }
        }

        const mappingOfPurchasedBoreholeName = (x, y, idx, direction) => {
            let define_borehole_position;

            if(direction === '_vertical') {  
                if(mappingOfPurchasedWellPad(y, direction)) {
                    define_borehole_position = x === 2 ? idx + 3 : (x === 3 ? idx + 6 : idx);
                    if(props.objects.borehole.boreholes.find(borehole => borehole.orderNumber === define_borehole_position) !== undefined) {
                       return props.objects.borehole.boreholes.find(borehole => borehole.orderNumber === define_borehole_position).name
                    }
                    // return props.objects.borehole.boreholes.find(borehole => borehole.orderNumber === define_borehole_position).name !== undefined ?  props.objects.borehole.boreholes.find(borehole => borehole.orderNumber === define_borehole_position).name : ''
                }        
            } else if(direction === '_horizontal') {  
                if(mappingOfPurchasedWellPad(x, direction)) {                    
                    define_borehole_position = y === 2 ? idx + 3 : (y === 3 ? idx + 6 : idx);
                    if(props.objects.borehole.boreholes.find(borehole => borehole.orderNumber === define_borehole_position) !== undefined) {
                       return props.objects.borehole.boreholes.find(borehole => borehole.orderNumber === define_borehole_position).name
                    }
                }
            }    
        }

        const mappingOfPurchasedBorehole = (x, y, idx, direction) => {
            let define_borehole_position;
            
            if(direction === '_vertical') {  
                if(mappingOfPurchasedWellPad(y, direction)) {
                    define_borehole_position = x === 2 ? idx + 3 : (x === 3 ? idx + 6 : idx)                    

                    return props.objects.borehole.boreholes.some(borehole => borehole.orderNumber === define_borehole_position)
                }        
            } else if(direction === '_horizontal') {  
                if(mappingOfPurchasedWellPad(x, direction)) {                    
                    define_borehole_position = y === 2 ? idx + 3 : (y === 3 ? idx + 6 : idx);

                    return props.objects.borehole.boreholes.some(borehole => borehole.orderNumber === define_borehole_position)
                }
            }            
        }

        const isCellInLine = (row: null | number, column: null | number, direction: string = '') => {
            if (hoveredColumn.state === -1 || hoveredRow.state === -1) {
                return false;
            }

            if (direction === '_vertical' && column === hoveredColumn.state && row === null) {                
                return true;
            }

            if (direction === '_horizontal' && row === hoveredRow.state && column === null) {
                return true;
            }

            return false;
        }

        const hightlightLine = (row: number | null, column: number | null) => {
            hoveredRow.state = row
            hoveredColumn.state = column
        }

        const removeHighlighting = () => {
            hoveredRow.state = null
            hoveredColumn.state = null
        }

        const openActiveTypeModal = (x: number, y: number) => {
            let url: string

            if(props.data_type === 'arrangement') {
                url = 'Views/Browser/Map/Purchase/ObjectOfArrangement'

                axios
                    .get(url)
                    .then(resp_arrangement => data_modal.data = resp_arrangement.data.table)
                    .finally(() => {
                        store.commit('modal/openModal', {
                            newState: 'mapModal',
                            props: {
                                coords: {
                                    cell: {
                                        x: x,
                                        y: y
                                    },
                                    region: {
                                        x: props.coords_region.x,
                                        y: props.coords_region.y
                                    },
                                },
                                table: data_modal.data
                            }
                        })
                    })
            }
        }
        const openOtherInfo = (multiply, idx) => {
            let order_id = multiply === 2 ? idx + 3 : (multiply === 3 ? idx + 6 : idx)
            if(props.objects.borehole.boreholes.find(borehole => borehole.orderNumber === order_id).status !== 'Project') {
                store.commit('modal/openModal', {
                    newState: 'otherModal',
                    props: {
                        order_id: props.objects.borehole.boreholes.find(borehole_id => borehole_id.orderNumber === order_id).boreholeId
                    }
                })
            }
        }


        watch(props.objects, (line) => {
            well_pad.id = line.borehole.id            
        })

        return {
            well_pad,
            openOtherInfo,
            openActiveTypeModal,
            removeHighlighting,
            hightlightLine,
            isCellInLine,
            mappingOfPurchasedBorehole,
            mappingOfPurchasedBoreholeName,
            buyWellPad,
            mappingOfPurchasedWellPad
        }
    }
})
