import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_cmp = _resolveComponent("checkbox-cmp")!
  const _component_button_cmp = _resolveComponent("button-cmp")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.main_class}__3d`)
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass(`${_ctx.main_class}__3d__title`)
    }, "Добавление 3D-модели", 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__3d__text-wrapper`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.text.points, (point, idx) => {
        return (_openBlock(), _createElementBlock("span", {
          class: _normalizeClass(`${_ctx.main_class}__3d__text`),
          key: idx
        }, [
          (point.link)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(point.text) + " ", 1),
                _createElementVNode("a", {
                  href: point.link.url,
                  class: _normalizeClass(point.link.class),
                  target: "_blank"
                }, _toDisplayString(point.link.text), 11, _hoisted_1),
                _createTextVNode(" " + _toDisplayString(point.tail), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(point.text), 1)
              ], 64))
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__3d__kinds`)
    }, [
      _createElementVNode("a", {
        class: _normalizeClass(`${_ctx.main_class}__3d__subtitle`)
      }, "Вид 3D-модели", 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.TD_models.value, (model) => {
        return (_openBlock(), _createBlock(_component_checkbox_cmp, {
          key: model.propertyId,
          class: _normalizeClass({_disabled: !model.isAvailable}),
          text: model.text,
          type_checkbox: model.text,
          id: model.propertyId,
          onSetObjCheckbox: _ctx.createArray3dmodel
        }, null, 8, ["class", "text", "type_checkbox", "id", "onSetObjCheckbox"]))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.main_class}__btns`)
    }, [
      _createVNode(_component_button_cmp, {
        class: _normalizeClass(`${_ctx.main_class}__btns__btn`),
        text: 'Отменить',
        background: false,
        onClick: _ctx.closeModal
      }, null, 8, ["class", "onClick"]),
      _createVNode(_component_button_cmp, {
        class: _normalizeClass({'_disabled': !_ctx.has_checked_models}),
        text: 'Далее',
        onClick: _ctx.addModelModal
      }, null, 8, ["class", "onClick"])
    ], 2)
  ], 2))
}