
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        direction: {
            type: [String, Object],
            default: ''
        }
    },
    setup() {
    }
})
