 
import { Chart } from 'highcharts-vue'
import buttonCmp from "../../components/ui-components/oil-button/button-cmp.vue"
// import switchCmp from "../../components/ui-components/oil-switch/switch-cmp.vue"
import menubarCmp from "../../components/ui-components/oil-menubar/menubar-cmp.vue"
// import InputCmp from "../../components/ui-components/oil-input/input-cmp.vue"
// import { mapMutations, mapState } from "vuex"
import axios from "axios"
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, reactive, onMounted, watch } from 'vue'

interface ResponseData {
    name: string;
    boreholeLog: {
        typeMarker: string;
    }[];
    wellTops: {
        name: string;
        z: number;
        color: string;
    }[];
}

    export default defineComponent({
        setup() {
            const store = useStore()
            const route = useRoute()
            
            const menubar_list = [
                {
                    text: "ГИС",
                    id: 1
                },
                {
                    text: "РИГИС",
                    id: 2
                },
                {
                    text: "ПГИ",
                    id: 3
                },
                {
                    text: "ГДИ",
                    id: 4
                },
                {
                    text: "ГДК",
                    id: 5
                },
            ]

            const borehole_name = reactive({
                name: ''
            })
            const dots_data = reactive({
                data: [] as any,
                filter_data: [] as any,
                t_nav_data: [] as any
            }) 

            // const levels = reactive({
            //     level_data: []
            // })

            const coords = reactive({
                x: 0,
                y: 0
            })

            // const chart_options = reactive({
            //     chart: {
            //         type: 'polygon',
            //         height: 600,
            //         width: 60,
            //         chartWidth: 30,
            //         spacing: [20, 0, 10, -2],
            //     },
            //     legend: {
            //         enabled: false,
            //     },
            //     xAxis: {
            //         labels: {
            //             enabled: false
            //         }
            //     },
            //     yAxis: {
            //         gridLineWidth: 0,
            //         max: -800,
            //         min: -2100,
            //         startOnTick: false,
            //         title: {
            //             text: null
            //         },
            //         labels: {
            //             enabled: true
            //         }
            //     },
            //     title: {
            //         text: "_",
            //         style: {
            //             color: "#fff"
            //         }
            //     },
            //     subtitle: {
            //         text: "_",
            //         style: {
            //             color: "#fff"
            //         }
            //     },
            //     tooltip: {
            //         enabled: false
            //     },
            //     series: [
            //         {
            //             name: 'Target',
            //             data: [
            //                 [1, -800],
            //                 [0, -800],
            //                 [0, -2100],
            //                 [1, -2100],
            //             ]
            //         },
            //     ],
            // })
                // levels: [],
                // x: 0,
                // y: 0,
                // level: true,
                // topPosition: 0,
                // heightGraphics: 1500,
                // clearData: "",
                // chartOptions: {
                    // chart: {
                    //     type: 'polygon',
                    //     height: 600,
                    //     width: 60,
                    //     chartWidth: 30,
                    //     spacing: [20, 0, 10, -2],
                    // },
                    // legend: {
                    //     enabled: false,
                    // },
                    // xAxis: {
                    //     labels: {
                    //         enabled: false
                    //     }
                    // },
                    // yAxis: {
                    //     gridLineWidth: 0,
                    //     max: -800,
                    //     min: -2100,
                    //     startOnTick: false,
                    //     title: {
                    //         text: null
                    //     },
                    //     labels: {
                    //         enabled: true
                    //     }
                    // },
                    // title: {
                    //     text: "_",
                    //     style: {
                    //         color: "#fff"
                    //     }
                    // },
                    // subtitle: {
                    //     text: "_",
                    //     style: {
                    //         color: "#fff"
                    //     }
                    // },
                    // tooltip: {
                    //     enabled: false
                    // },
                    // series: [
                    //     {
                    //         name: 'Target',
                    //         data: [
                    //             [1, -800],
                    //             [0, -800],
                    //             [0, -2100],
                    //             [1, -2100],
                    //         ]
                    //     },
                    // ],
                    
                    
                // }
            // }

            const active_menubar = reactive({
                menu_id: 1
            })

            const selectMenu = (menu_id) => {
                active_menubar.menu_id = menu_id
            }

            const test_dots = {
                chart: {
                    type: 'column',
                    width:  250,
                    height: 1000,
                    inverted: true,
                    radius: 0.5,
                    spacing: [20, 0, 10, -9],
                    scrollablePlotArea: {
                        minHeight: 1000,
                        scrollPositionX: 1
                    }
                },
                xAxis: {
                    reversed: false,
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    },
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    pointFormat: 'Population in 2021: <b>{point.y:.1f} millions</b>'
                },
                series: [{
                    pointPadding: 0,
                    colorByPoint: true,
                    groupPadding: 0,
                    data: [
                        [37.33],
                        [31.18],
                        [27.79],
                        [22.23],
                        [21.91],
                        [21.74],
                        [21.32],
                        [20.89],
                        [20.67],
                        [19.11],
                        [16.45],
                        [16.38],
                        [15.41],
                        [15.25],
                        [14.974],
                        [14.970],
                        [14.86],
                        [14.16],
                        [13.79],
                        [13.64]
                    ],
                }]
            }

            const setOptions = (dataset, zoom, well_tops) => {
                return {
                    group_name: dataset.logNameGroupName,
                    colors: [dataset.color],
                    chart: {
                        type: dataset.typeMarker,
                        width:  250,
                        height: zoom,
                        inverted: true,
                        radius: 0.5,
                        spacing: [20, 0, 10, -9],
                        scrollablePlotArea: {
                            minHeight: 1000,
                            scrollPositionX: 1
                        }
                    },
                    legend: {
                        enabled: false,
                    },
                    tooltip: {
                        pointFormat: 'x {point.x}, y {point.y}'
                    },
                    title: {
                        text: dataset.name
                    },
                    subtitle: {
                        text: [`ср. и макс. значения </br>${dataset.unit}`]
                    },
                    xAxis: {
                        reversed: false,
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: dataset.labels
                        },
                        plotLines: well_tops.map((well_tops) => {
                            return { 
                                label: {
                                    text: well_tops.name,
                                },
                                color: well_tops.color,
                                value: well_tops.z,
                                zIndex: 5,
                                width: 2,
                                dashStyle: 'Solid'
                            }
                        })
                    },
                    yAxis: {
                        min: dataset.typeMarker === 'polygon' ? 0 : null,
                        max: dataset.typeMarker === 'polygon' ? 1 : null,
                        gridLineWidth: 0,
                        title: {
                            text: null
                        },
                        labels: {
                            enabled: false
                        }
                    },
                    events: {
                        click: (e) => {
                            coords.x = e.xAxis[0].value
                            coords.y = e.yAxis[0].value
                        }
                    },
                    series: dataset.series,
                    plotOptions: {
                        line: {
                            lineWidth: 3, 
                            lineJoin: 'round' 
                        },
                        scatter: {
                            marker: {
                                radius: 2.5,
                                symbol: 'circle'
                            },
                        },
                        series: {
                            cursor: 'pointer',
                        }
                    },
                }
            }


            watch(() => active_menubar.menu_id, (newValue) => {
                switch (newValue) {
                    case 1:                        
                        dots_data.filter_data = dots_data.data.filter(graphics => graphics.group_name === 'ГИС')

                        break;
                    
                    case 2:
                        dots_data.filter_data = dots_data.data.filter(graphics => graphics.group_name === 'РИГИС')
                        if(dots_data.filter_data.length) {
                            dots_data.filter_data[0].xAxis.labels.enabled = true
                        }
                        break;
                    
                    case 3:
                        dots_data.filter_data = dots_data.data.filter(graphics => graphics.group_name === 'ПГИ')
                        if(dots_data.filter_data.length) {
                            dots_data.filter_data[0].xAxis.labels.enabled = true
                        }
                        break;
                    
                    case 4:
                        dots_data.filter_data = dots_data.data.filter(graphics => graphics.group_name === 'ГДИ')
                        if(dots_data.filter_data.length) {
                            dots_data.filter_data[0].xAxis.labels.enabled = true
                        }
                        break;
                    
                    case 5:
                        dots_data.filter_data = dots_data.data.filter(graphics => graphics.group_name === 'ГДК')
                        if(dots_data.filter_data.length) {
                            dots_data.filter_data[0].xAxis.labels.enabled = true
                        }
                        break;
                    
                    default:
                        break;
                }
            })


            onMounted(() => {
                axios
                    .get<ResponseData>(`api/v1/LithologicalData/BoreholeLog/${route.params.id}`)
                    .then((resp) => {
                        borehole_name.name = resp.data.name
                        dots_data.data = resp.data.boreholeLog.map((dataset)=> {
                            return dataset.typeMarker !== 'None' ? setOptions(dataset, 1000, resp.data.wellTops) : ''
                        })}
                    )
                    .catch(err => {                 
                        store.commit('notification/openNotification', {
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        })
                    })
                    .finally(() => {
                        dots_data.filter_data = dots_data.data.filter(graphics => graphics.group_name === 'ГИС' )
                    })
                
                axios
                    .get(`Api/V1/LithologicalData/BoreholeLog/Pbus?boreholeId=${route.params.id}`)
                    .then((resp) => {
                        dots_data.t_nav_data = resp.data
                    })
                    .catch((err) => {
                        store.commit('notification/openNotification',{
                            error: true,
                            title: 'Ошибка!',
                            desc: err.response.data
                        })
                    })
                
                // axios
                //     .get(`api/v1/Purchased/WellTop/${this.$route.params.id}`)
                //     .then(resp => {
                //         this.levels = resp.data
                //     })
            })

            return {
                selectMenu,
                active_menubar,
                menubar_list,
                dots_data,
                test_dots
            }
        },
        // data() {
        //     return {
        //         borehole_name: '',
        //         curtain: false,
        //         dots: [],
        //         zoom: 1500,
        //         levels: [],
        //         x: 0,
        //         y: 0,
        //         level: true,
        //         topPosition: 0,
        //         heightGraphics: 1500,
        //         clearData: "",
        //         chartOptions: {
        //             chart: {
        //                 type: 'polygon',
        //                 height: 600,
        //                 width: 60,
        //                 chartWidth: 30,
        //                 spacing: [20, 0, 10, -2],
        //             },
        //             legend: {
        //                 enabled: false,
        //             },
        //             xAxis: {
        //                 labels: {
        //                     enabled: false
        //                 }
        //             },
        //             yAxis: {
        //                 gridLineWidth: 0,
        //                 max: -800,
        //                 min: -2100,
        //                 startOnTick: false,
        //                 title: {
        //                     text: null
        //                 },
        //                 labels: {
        //                     enabled: true
        //                 }
        //             },
        //             title: {
        //                 text: "_",
        //                 style: {
        //                     color: "#fff"
        //                 }
        //             },
        //             subtitle: {
        //                 text: "_",
        //                 style: {
        //                     color: "#fff"
        //                 }
        //             },
        //             tooltip: {
        //                 enabled: false
        //             },
        //             series: [
        //                 {
        //                     name: 'Target',
        //                     data: [
        //                         [1, -800],
        //                         [0, -800],
        //                         [0, -2100],
        //                         [1, -2100],
        //                     ]
        //                 },
        //             ],
                    
                    
        //         }
        //     }
        // },
        components: {
            highcharts: Chart,
            'button-cmp': buttonCmp,
            // 'switch-cmp': switchCmp,
            'menubar-cmp': menubarCmp
            // 'input-cmp': InputCmp
        },

        // watch: {
        //     heightGraphics() {
        //         this.curtain = true

        //         const url = `api/v1/LithologicalData/BoreholeLog/${this.$route.params.id}`
        //         this.$root.getInfo(url)
        //             .then((resp) => {
        //                 this.curtain = false
        //                 this.dots = resp.boreholeLog.map((dataset)=> {
        //                 return dataset.typeMarker !== 'None' ? this.setOptions(dataset, this.heightGraphics) : ''

        //             })})
        //             .catch(() => this.$root.visibleNotification({
        //                 props: {
        //                     error: true,
        //                     title: "Ошибка",
        //                     desc: 'Что-то пошло не так, попробуйте позже'
        //                 }
        //             }))
        //     },
        //     update() {
        //         if(this.update) {
        //             axios
        //                 .get(`api/v1/LithologicalData/BoreholeLog/${this.$route.params.id}`)
        //                 .then((resp) => {
        //                     this.curtain = false
        //                     this.borehole_name = resp.data.name
        //                     this.dots = resp.data.boreholeLog.map((dataset)=> {
        //                         return dataset.typeMarker !== 'None' ? this.setOptions(dataset, this.heightGraphics, resp.data.wellTops) : ''
        //                     })}
        //                 )
        //                 .catch(err => console.log(err))
        //                 .finally(() => {
        //                     this.updateMap('')
        //                 })
        //         }
        //     }
        // },
        // methods: {
        //     ...mapMutations('modal', ['openModal']),
        //     ...mapMutations('request', ['updateMap']),

        //     setValue(val) {
        //         this.zoom = Number(val.value)
        //     },
        //     openLevel() {
        //         this.openModal({
        //             newState: 'levelsModal',
        //             props: {
        //                 dots: this.x
        //             }
        //         })
        //     },
        //     setHeigth(z) {
        //         return z < 2220 ? z : (z < 2240 ? z + (111 * 0.84) : (z < 2260 ? z + (111 * 1.65) : (z < 2280 ? z + (111 * 2.6) : (z < 2300 ? z + (111 * 3.5) : z < 2320 ? z + (111 * 4.5) : (z < 2340 ? z + (111 * 5) : z < 2360 ? z + (111 * 6): z < 2380 ? (111 * 8) : z <  2400 ? z + (111 * 8) : z < 2420 ? z + (111 * 9) : z < 2440 ? z + (111 * 9) : z + 2116)))))
        //     },
        //     setOptions(dataset, zoom, well_tops) {
        //         return {
        //             colors: [dataset.color],
        //             chart: {
        //                 type: dataset.typeMarker,
        //                 width:  250,
        //                 height: zoom,
        //                 inverted: true,
        //                 radius: 0.5,
        //                 spacing: [20, 0, 10, -9],
        //                 scrollablePlotArea: {
        //                     minHeight: 1000,
        //                     scrollPositionX: 1
        //                 }
        //             },
        //             legend: {
        //                 enabled: false,
        //             },
        //             tooltip: {
        //                 pointFormat: 'x {point.x}, y {point.y}'
        //             },
        //             title: {
        //                 text: dataset.name
        //             },
        //             subtitle: {
        //                 text: dataset.unit
        //             },
        //             xAxis: {
        //                 reversed: false,
        //                 title: {
        //                     text: null
        //                 },
        //                 labels: {
        //                     enabled: dataset.labels
        //                 },
        //                 plotLines: well_tops.map((well_tops) => {
        //                     return { 
        //                         label: {
        //                             text: well_tops.name,
        //                         },
        //                         color: well_tops.color,
        //                         value: well_tops.z,
        //                         zIndex: 5,
        //                         width: 2,
        //                         dashStyle: 'Solid'
        //                     }
        //                 })
        //             },
        //             yAxis: {
        //                 min: dataset.typeMarker === 'polygon' ? 0 : null,
        //                 max: dataset.typeMarker === 'polygon' ? 1 : null,
        //                 gridLineWidth: 0,
        //                 title: {
        //                     text: null
        //                 },
        //                 labels: {
        //                     enabled: false
        //                 }
        //             },
        //             events: {
        //                 click: (e) => {
        //                     this.x = e.xAxis[0].value
        //                     this.y = e.yAxis[0].value
        //                 }
        //             },
        //             series: dataset.series,
        //             plotOptions: {
        //                 scatter: {
        //                     marker: {
        //                         radius: 2.5,
        //                         symbol: 'circle'
        //                     },
        //                 },
        //                 series: {
        //                     cursor: 'pointer',
        //                     point: {
        //                         events: {
        //                             mouseOver: (e) => {this.x = e.target.x},
        //                         }
        //                     }
        //                 }
        //             },
        //         }
        //     },
        //     setToggle(val) {
        //         this.level = val
        //     },
        //     changeZoom() {
        //         this.heightGraphics = this.zoom
        //     },
        //     zoomGraphics(e) {
        //         console.log(e);
        //     },
        //     sync(event, type) {
        //         this.$refs.highcharts.forEach(({ chart }) => {
        //             if (chart === this.series.chart) return;
        //             chart.series.forEach((series) => {
        //             series.data.forEach((point) => {
        //                 if (point.x === this.x) {
        //                 if (type === 'over') {
        //                     point.setState('hover');
        //                     chart.tooltip.refresh(point);
        //                     chart.xAxis[0].drawCrosshair(event, point);
        //                 } else {
        //                     point.setState();
        //                     chart.tooltip.hide();
        //                     chart.xAxis[0].hideCrosshair();
        //                 }
        //                 }
        //             });
        //             });
        //         });
        //     },
        //     levelChoose(e) {
        //         this.topPosition = e.chartY
        //     }
        // },
        // computed: {
        //     ...mapState('request', ['update']),
        // },
        // created() {
        //     this.curtain = true

        //     axios.get(`api/v1/LithologicalData/BoreholeLog/${this.$route.params.id}`)
        //         .then((resp) => {
        //             this.curtain = false
        //             this.borehole_name = resp.data.name
        //             // this.chart_settings.value = [resp.data.boreholeLog, resp.data.WellTop]
        //             this.dots = resp.data.boreholeLog.map((dataset)=> {
        //                 return dataset.typeMarker !== 'None' ? this.setOptions(dataset, this.heightGraphics, resp.data.wellTops) : ''
        //             })}
        //         )
        //         .catch(err => console.log(err))
            
        //     axios
        //         .get(`api/v1/Purchased/WellTop/${this.$route.params.id}`)
        //         .then(resp => {
        //             this.levels = resp.data
        //         })
        // },
    })
